import React, { useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import SupportAgentIcon from '@material-ui/icons/ContactSupport';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import NutreGrowLogo from '../images/Landing/NutreGrow-removebg.png';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MailIcon from '@material-ui/icons/Mail';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Card, CardContent, Grid, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import SaveIcon from '@material-ui/icons/Save';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SyncIcon from '@material-ui/icons/Sync';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import RestaurantDetails from '../components/BusinessRegistration/RestaurantDetails';
import { Button, Container } from '@material-ui/core';
import { useSharedState } from '../helpers/sharedState';
import TradeRegistrations from '../components/BusinessRegistration/TradeRegistrations';
import BrandDetails from '../components/BusinessRegistration/BrandDetails';
import OMS from '../components/ProductConfig/OMS';
import QMS from '../components/ProductConfig/QMS';
import IMS from '../components/ProductConfig/IMS';
import CMS from '../components/ProductConfig/CMS';
import DeliveryPayment from '../components/ProductConfig/DeliveryPayment';
import Verification from './Verification';
import { api } from '../helpers/urls';
// import NutreGrowLogo from '../images/NutreGrow.png';
import { SignalWifiOffOutlined } from '@material-ui/icons';
import SupportMessageModal from './SupportMessageModal';

function Alert(props) {
	return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	drawer: {
		[theme.breakpoints.up('sm')]: {
			width: drawerWidth,
			flexShrink: 0,
			// position:'absolute',
		},
	},
	appBar: {
		[theme.breakpoints.up('sm')]: {
			width: `calc(100% - ${drawerWidth}px)`,
			marginLeft: drawerWidth,
		},
	},
	menuButton: {
		marginRight: theme.spacing(2),
		[theme.breakpoints.up('sm')]: {
			display: 'none',
		},
	},
	// necessary for content to be below app bar
	toolbar: theme.mixins.toolbar,
	drawerPaper: {
		width: drawerWidth,
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
	nested: {
		paddingLeft: theme.spacing(5),
	},
	form: {
		width: '100%',
	},
	'resoee-img': {
		position: 'relative',
		'z-index': 20,
		maxWidth: drawerWidth,
		// 'max-height': '50px',
		maxHeight: '45px',
	},
	header: {
		position: 'absolute',
	},
	'resoee-img-container': {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		maxHeight: '64px',
		height: '100%',
	},
	'support-btn': {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
    '&:hover':{
      cursor:'pointer',
    }
	},
}));

export default function Account(props) {
	const { window } = props;
	const classes = useStyles();
	const theme = useTheme();
	const [mobileOpen, setMobileOpen] = React.useState(false);
	const [supportOpen, setSupportOpen] = React.useState(false);
	const [saving, setSaving] = React.useState(false);
	const [open, setOpen] = React.useState(false);
	const [message, setMessage] = React.useState(false);
	const handleSupportOpen = () =>{ setSupportOpen(true)};
	const handleSupportClose = () => {setSupportOpen(false)};
	const snackbarAlert = (message) => {
		setOpen(true);
		setMessage(message);
	};
	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
	};

	const {
		userEmail,
		userToken,
		companyName,
		setCompanyName,
		headOfficeAddress,
		setHeadOfficeAddress,
		selectedCity,
		setSelectedCity,
		pincode,
		setPincode,
		selectedState,
		setSelectedState,
		managerName,
		setManagerName,
		managerPhone,
		setManagerPhone,
		gstNumber,
		setGstNumber,
		fssaiNumber,
		setFssaiNumber,
		brandName,
		setBrandName,
		brandDescription,
		setBrandDescription,
		selectedTheme,
		setSelectedTheme,
		omsConfig,
		setOmsConfig,
		qmsConfig,
		setQmsConfig,
		imsConfig,
		setImsConfig,
		cmsConfig,
		setCmsConfig,
		deliveryEnabled,
		setDeliveryEnabled,
		paymentEnabled,
		setPaymentEnabled,
		paymentConfig,
		setPaymentConfig,
		deliveryConfig,
		setDeliveryConfig,
		companyCert,
		setCompanyCert,
		companyCertName,
		setCompanyCertName,
		fssaiCert,
		setFssaiCert,
		fssaiCertName,
		setFssaiCertName,
		businessPan,
		setBusinessPan,
		businessPanName,
		setBusinessPanName,
		identityProof,
		setIdentityProof,
		identityProofName,
		setIdentityProofName,
		cancelledCheque,
		setCancelledCheque,
		cancelledChequeName,
		setCancelledChequeName,
		brandLogo,
		setBrandLogo,
		brandLogoFilename,
		setBrandLogoFilename,
		trademarkCert,
		setTrademarkCert,
		tmCertFilename,
		setTmFilename,
		fileUrl,
		setFileUrl,
		signOut,
		businessDisplayName,
		setBusinessDisplayName,
	} = useSharedState();

	async function saveConfigHandler(calledFromProps = false) {
		setSaving(true);
		try {
			const data = {
				email: userEmail,
				businessDetails: {
					businessName: companyName,
					businessAddress: headOfficeAddress,
					state: selectedState,
					city: selectedCity,
					gstNumber: gstNumber,
					fssaiNumber: fssaiNumber,
					gstCertificate: '',
					companyCertificate: companyCert,
					fssaiCertificate: fssaiCert,
					businessPAN: '',
					identityProof: identityProof,
					cancelledCheque: cancelledCheque,
					brandName: brandName,
					brandDescription: brandDescription,
					primaryThemeColor: selectedTheme.primary,
					secondaryThemeColor: selectedTheme.secondary,
					managerName: managerName,
					managerPhone: managerPhone,
					pincode: pincode === '' ? null : pincode,
					businessDisplayName: businessDisplayName,
				},
				oms: {
					enabled: omsConfig.enabled,
					kot_printing: omsConfig.printing.ipEnabled,
					qr_printing: omsConfig.printing.ipEnabled,
				},
				qms: {
					enabled: qmsConfig.enabled,
					camera: qmsConfig.qualityCheck.image,
					weight: qmsConfig.qualityCheck.weight,
					temp: qmsConfig.qualityCheck.temp,
					kot_printing: qmsConfig.printing.ipEnabled,
					qr_printing: qmsConfig.printing.ipEnabled,
				},
				ims: {
					enabled: imsConfig.enabled,
					camera: imsConfig.qualityCheck.image,
					weight: imsConfig.qualityCheck.weight,
					temp: imsConfig.qualityCheck.temp,
					kot_printing: imsConfig.printing.ipEnabled,
					qr_printing: imsConfig.printing.ipEnabled,
				},
				cms: {
					enabled: cmsConfig.enabled,
					item_scan: cmsConfig.itemScan,
					camera: cmsConfig.stationCamera,
				},
				delivery: {
					enabled: deliveryEnabled,
					self_delivery: deliveryConfig.ownDelivery,
					tracking: deliveryConfig.dunzo,
				},
				payment: {
					digital: paymentConfig.digital,
					cod: paymentConfig.cod,
				},
			};
			const formData = new FormData();
			formData.append('data', JSON.stringify(data));
			//if(gstCertificate !== null) formData.append('gstCertificate',gstCertificate);
			if (companyCert !== null)
				formData.append('companyCertificate', companyCert);
			if (fssaiCert !== null)
				formData.append('fssaiCertificate', fssaiCert);
			if (businessPan !== null)
				formData.append('companyPAN', businessPan);
			if (identityProof !== null)
				formData.append('identityProof', identityProof);
			if (cancelledCheque !== null)
				formData.append('cancelledCheque', cancelledCheque);
			if (brandLogo !== null) formData.append('brandLogo', brandLogo);
			if (trademarkCert !== null)
				formData.append('tradeMarkCertificate', trademarkCert);
			const response = await fetch(api.saveConfig, {
				method: 'POST',
				headers: {
					Token: userToken,
				},
				body: formData,
			});

			const res = await response.json();
			if (res.errorMessage) {
				snackbarAlert(res.errorMessage);
				if (calledFromProps) return false;
			} else {
				snackbarAlert('Details Saved');
				fetchConfig(true);
				if (calledFromProps) return true;
			}
			setSaving(false);
		} catch (err) {
			setSaving(false);
			console.log(err);
			snackbarAlert('Could not save configuration. Please try again.');
		}
	}

	async function fetchConfig(afterSave = false) {
		try {
			const response = await fetch(
				api.getConfig + `?email=${userEmail}`,
				{
					method: 'GET',
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
						Token: userToken,
						// 'email': userEmail
					},
				},
				5000
			);

			const res = await response.json();
			if (res.errorMessage) {
				//snackbarAlert(res.errorMessage);
			} else {
				setCompanyName(res.businessDetails.businessName);
				setHeadOfficeAddress(res.businessDetails.businessAddress);
				setManagerName(res.businessDetails.managerName);
				setManagerPhone(res.businessDetails.managerPhone);
				setSelectedCity(res.businessDetails.city);
				setSelectedState(res.businessDetails.state);
				setGstNumber(res.businessDetails.gstNumber);
				setFssaiNumber(res.businessDetails.fssaiNumber);
				setPincode(res.businessDetails.pincode);
				setBusinessDisplayName(res.businessDetails.businessDisplayName);
				setSelectedTheme({
					...selectedTheme,
					primary: res.businessDetails.primaryThemeColor,
					secondary: res.businessDetails.secondaryThemeColor,
				});
				setBrandName(res.businessDetails.brandName);
				setBrandDescription(res.businessDetails.brandDescription);
				setFileUrl({
					...fileUrl,
					companyCert: res.businessDetails.companyCertificateUrl,
					fssaiCert: res.businessDetails.fssaiUrl,
					businessPan: res.businessDetails.companyPANUrl,
					identityProof: res.businessDetails.identityProofUrl,
					cancelledCheque: res.businessDetails.cancelledChequeUrl,
					trademarkCert: res.businessDetails.tradeMarkCertificateUrl,
					brandLogo: res.businessDetails.brandLogoUrl,
				});
				setOmsConfig({
					enabled: res.oms.enabled,
					printing: {
						ipEnabled: res.oms.kot_printing,
					},
				});
				setQmsConfig({
					enabled: res.qms.enabled,
					qualityCheck: {
						image: res.qms.camera,
						weight: res.qms.weight,
						temp: res.qms.temp,
					},
					printing: {
						ipEnabled: res.qms.kot_printing,
					},
				});
				setImsConfig({
					enabled: res.ims.enabled,
					qualityCheck: {
						image: res.ims.camera,
						weight: res.ims.weight,
						temp: res.ims.temp,
					},
					printing: {
						ipEnabled: res.ims.kot_printing,
					},
				});
				setCmsConfig({
					enabled: res.cms.enabled,
					itemScan: res.cms.item_scan,
					stationCamera: res.cms.camera,
				});
				setDeliveryEnabled(res.delivery.enabled);
				setPaymentConfig({
					cod: res.payment.cod,
					digital: res.payment.digital,
				});
				setDeliveryConfig({
					ownDelivery: res.delivery.self_delivery,
					dunzo: res.delivery.tracking,
				});
				// if(!afterSave) snackbarAlert('Synced details successfully!');
			}
		} catch (err) {
			console.log(err);
			//snackbarAlert('Unable to get configuration at this time, Please try again.')
		}
	}

	useEffect(() => {
		fetchConfig();
	}, []);

	const pageMap = {
		'Restaurant Details': 0,
		'Trade Registrations': 1,
		'Brand Details': 2,
		'Order Management': 3,
		'Quality Management': 4,
		'Inventory Management': 5,
		'Cooking Management': 6,
		'Delivery & Payment': 7,
		Verification: 8,
	};

	const navMap = {
		'Business Registration': [
			'Restaurant Details',
			'Trade Registrations',
			'Brand Details',
		],
		'Product Configuration': [
			'Order Management',
			'Quality Management',
			'Inventory Management',
			'Cooking Management',
			'Delivery & Payment',
		],
	};

	const [drawer1, setDrawer1] = React.useState(true);
	const [drawer2, setDrawer2] = React.useState(false);

	const [businessRegistrationCompleted, setBusinessRegistrationCompleted] =
		React.useState({
			'Restaurant Details': false,
			'Trade Registrations': false,
			'Brand Details': false,
		});
	const [productConfigCompleted, setProductConfigCompleted] =
		React.useState(false);
	const [verificationCompleted, setVerificationCompleted] =
		React.useState(false);

	const [pageCounter, setPageCounter] = React.useState(0);

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const drawer = (
		<div>
			<div className={`${classes.toolbar} ${classes.header}`} />
			<div className={classes['resoee-img-container']}>
				<img
					className={classes['resoee-img']}
					src={NutreGrowLogo}
					alt='NutreGrow Logo'
				/>
			</div>
			<Divider />
			<List>
				<ListItem
					button
					style={{ backgroundColor: 'white' }}
					onClick={() => {
						setDrawer1(!drawer1);
						setDrawer2(false);
					}}
				>
					<ListItemText primary='Business Registration' />
					{/* {false ? <CheckCircleIcon style={{ color: "green" }}/> : <ErrorIcon style={{ color: "orange" }}/>} */}
				</ListItem>
			</List>
			<Collapse in={drawer1} unmountOnExit>
				<List disablePadding>
					{navMap['Business Registration'].map((elem) => {
						return (
							<ListItem
								onClick={() => setPageCounter(pageMap[elem])}
								button
								className={classes.nested}
							>
								<ListItemText primary={elem} />
								{/* {businessRegistrationCompleted[elem] ? <CheckCircleIcon style={{ color: "green" }}/> : <ErrorIcon style={{ color: "orange" }}/>} */}
							</ListItem>
						);
					})}
				</List>
			</Collapse>
			<Divider />
			<List>
				<ListItem
					button
					style={{ backgroundColor: 'white' }}
					onClick={() => {
						setDrawer2(!drawer2);
						setDrawer1(false);
					}}
				>
					<ListItemText primary='Product Configuration' />
					{/* {productConfigCompleted ? <CheckCircleIcon style={{ color: "green" }}/> : <ErrorIcon style={{ color: "orange" }}/>} */}
				</ListItem>
			</List>
			<Collapse in={drawer2} unmountOnExit>
				<List disablePadding>
					{navMap['Product Configuration'].map((elem) => {
						return (
							<ListItem
								onClick={() => setPageCounter(pageMap[elem])}
								button
								className={classes.nested}
							>
								<ListItemText primary={elem} />
							</ListItem>
						);
					})}
				</List>
			</Collapse>
			<Divider />
			<List>
				<ListItem
					button
					style={{ backgroundColor: 'white' }}
					onClick={() => setPageCounter(8)}
				>
					<ListItemText primary='Verification' />
					{/* {verificationCompleted ? <CheckCircleIcon style={{ color: "green" }}/> : <ErrorIcon style={{ color: "orange" }}/>} */}
				</ListItem>
			</List>
			<Divider />
			<List>
				{/* <Button disabled={saving} onClick={saveConfigHandler} startIcon={<SaveIcon/>} variant="text" fullWidth color="primary">
          {saving?"Saving":"Save"}
        </Button>
        <Button onClick={()=>fetchConfig()} startIcon={<SyncIcon/>} variant="text" fullWidth color="primary">
          Sync
        </Button> */}
				<Button
					onClick={() => signOut()}
					startIcon={<ExitToAppIcon />}
					variant='text'
					fullWidth
					color='primary'
				>
					Sign Out
				</Button>
			</List>
			<SupportMessageModal
				handleClose={handleSupportClose}
				open={supportOpen}
			/>
			<div onClick={handleSupportOpen} className={classes['support-btn']}>
				Support
        <SupportAgentIcon />
			</div>
		</div>
	);

	const container =
		window !== undefined ? () => window().document.body : undefined;

	return (
		<div className={classes.root}>
			<CssBaseline />
			<AppBar position='fixed' className={classes.appBar}>
				<Toolbar>
					<IconButton
						color='inherit'
						aria-label='open drawer'
						edge='start'
						onClick={handleDrawerToggle}
						className={classes.menuButton}
					>
						<MenuIcon />
					</IconButton>
					<Typography variant='h5' noWrap>
						Restaurant Setup
					</Typography>
				</Toolbar>
			</AppBar>
			<nav className={classes.drawer}>
				{/* The implementation can be swapped with js to avoid SEO duplication of links. */}
				<Hidden smUp implementation='css'>
					<Drawer
						container={container}
						variant='temporary'
						anchor={theme.direction === 'rtl' ? 'right' : 'left'}
						open={mobileOpen}
						onClose={handleDrawerToggle}
						classes={{
							paper: classes.drawerPaper,
						}}
						ModalProps={{
							keepMounted: true, // Better open performance on mobile.
						}}
					>
						{drawer}
					</Drawer>
				</Hidden>
				<Hidden xsDown implementation='css'>
					<Drawer
						classes={{
							paper: classes.drawerPaper,
						}}
						variant='permanent'
						open
					>
						{drawer}
					</Drawer>
				</Hidden>
			</nav>
			<main className={classes.content}>
				<div className={classes.toolbar} />
				<Card
					elevation={7}
					style={{ paddingBottom: 15, marginBottom: 20 }}
				>
					<CardContent>
						<Container maxWidth='xs'>
							<div className={classes.form}>
								<Button
									style={{ float: 'left' }}
									variant='contained'
									startIcon={<ChevronLeftIcon />}
									color='primary'
									disabled={pageCounter === 0}
									onClick={() => {
										setPageCounter(pageCounter - 1);
										saveConfigHandler();
									}}
								>
									Previous
								</Button>
								<Button
									style={{ float: 'right' }}
									variant='contained'
									endIcon={<ChevronRightIcon />}
									color='primary'
									disabled={pageCounter === 8}
									onClick={() => {
										setPageCounter(pageCounter + 1);
										saveConfigHandler();
									}}
								>
									Next
								</Button>
							</div>
						</Container>
					</CardContent>
				</Card>
				{pageCounter === pageMap['Restaurant Details'] && (
					<RestaurantDetails />
				)}
				{pageCounter === pageMap['Trade Registrations'] && (
					<TradeRegistrations />
				)}
				{pageCounter === pageMap['Brand Details'] && <BrandDetails />}
				{pageCounter === pageMap['Store '] && <BrandDetails />}
				{pageCounter === pageMap['Order Management'] && <OMS />}
				{pageCounter === pageMap['Quality Management'] && <QMS />}
				{pageCounter === pageMap['Inventory Management'] && <IMS />}
				{pageCounter === pageMap['Cooking Management'] && <CMS />}
				{pageCounter === pageMap['Delivery & Payment'] && (
					<DeliveryPayment />
				)}
				{pageCounter === pageMap['Verification'] && (
					<Verification saveConfig={saveConfigHandler} />
				)}
			</main>
			<Snackbar open={open} autoHideDuration={1000} onClose={handleClose}>
				<Alert onClose={handleClose} severity='info'>
					{message}
				</Alert>
			</Snackbar>
		</div>
	);
}
