/** @format */

import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import "./Landing.css";
import { features } from "./features";
// import Register from '../Register';
// import Login from '../Login';
import { api } from "../../helpers/urls";
import Carousel from "react-elastic-carousel";
import { useLocation } from "react-router-dom";
import { VideoSection, VideoSection1 } from "../../components/Video Section";

function Landing(props) {
  const initialState = {
    slideIdx: features.features.map((f) => 0),
  };

  const [state, setState] = useState({ ...initialState });
  const [policyUrls, setPolicyUrls] = React.useState({
    TOS: "",
    privacyPolicy: "",
  });
  const [activeForm, setActiveForm] = React.useState("Register");

  const incrementSlide = (state) => {
    const { slideIdx } = state;
    return slideIdx.map((slideIdx, idx) => {
      let slideArr = features.features[idx].imgs.slice();
      let slideLen = slideArr.length;
      let newIdx = slideIdx + 1 >= slideLen ? 0 : slideIdx + 1;
      return newIdx;
    });
  };

  async function fetchAppConfig() {
    fetch(
      api.appConfig,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      },
      12000
    )
      .then((response) => response.json())
      .then(
        (res) => {
          if (res.errorMessage) {
            //do nothing for now
          } else {
            setPolicyUrls(res);
          }
        },
        (err) => {
          console.log(err);
          //do nothing for now
        }
      );
  }

  const location = useLocation().hash;

  useEffect(() => {
    // fetchAppConfig();
    // if (location === "#signup") {
    //   setActiveForm("Register");
    // } else {
    //   setActiveForm("Login");
    // }
    setInterval(() => {
      setState((prevState) => {
        return { slideIdx: incrementSlide(prevState) };
      });
    }, 15000);
  }, []);

  return (
    <div className='landing-page'>
      <div className='header'>
        <img className='brand-logo' src={features.header.logo} />
        {/* <a
          href='#contact'
		  className='contact'
          onClick={() => setActiveForm("Register")}>
          Contact Us
        </a> */}
      </div>
      <div className='hero-section'>
        <div className='hero-img-container'>
          <div className='hero-img'>
            <Carousel itemsToShow={1}>
              {features.features[0].imgs.map((item) => {
                return (
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src={item.src}
                  />
                );
              })}
            </Carousel>
          </div>
        </div>
        <div className='hero-details'>
          <img src={features.header.logoby} className='hero-logo' />
          <p className='heading'>{features.heroSection.heading}</p>
          {features.heroSection.subHeadings.map((subHeading) => {
            return <p className='sub-heading'>{subHeading}</p>;
          })}
        </div>
      </div>
      <div className='news-wrapper'>
        <VideoSection
          heading='Do You Know ?'
          description='In India, 94% of childern in the age group of 6 to 9 are mildly, moderately, or severly underweight.Malnutrition is a major public health problem in India. This affects the child at the most crucial period of time of development, which can lead to permanent impairment in later life.'
          url='https://m.youtube.com/watch?v=GT8YkCozJks'
        />
      </div>
      <div className='news-wrapper'>
        <VideoSection1
          heading='What We Do ?'
          description='Our device helps the school to capture and maintain a digital health record of each child.We provide 100% traceability of every meal given to individual student.Using nutregro device children with malnutrition, underweight, growth abnormalities can be well detected in advance.'
          url='https://youtu.be/YAtmqfwlOJY'
        />
      </div>
      <div className='features-wrapper'>
        <div className='features-section'>
          {features.features.map((feature) => {
            let cardItemsDOM = [],
              cardsDOM = [],
              badgeItemsDOM = [],
              badgesDOM = [],
              buttonsDOM = [];

            const { orientation } = feature;

            // hero section
            if (feature.id === 0) {
              return;
            }

            // store cards dom
            if (feature.id === 1) {
              cardItemsDOM = feature.cards.map((card) => {
                return (
                  <div className='card'>
                    <img className='card-img' src={card.img} />
                    <p className='heading'>
                      {card.text}{" "}
                      <span className='sub-text'>{card.subText}</span>
                    </p>
                    {card.items.map((item) => {
                      return <p className='item-text'>{item.text}</p>;
                    })}
                  </div>
                );
              });

              cardsDOM = <div className='cards'>{cardItemsDOM}</div>;
            }

            if (feature.id === 1) {
              buttonsDOM = (
                <div className='demo-store'>
                  <img
                    src={feature.buttons.demoStore.icon}
                    className='store-icon'
                    alt='Icon'
                  />
                </div>
              );
            }

            if (feature.id === 4) {
              badgeItemsDOM = feature.badges.map((badge) => {
                return (
                  <div className='badge'>
                    <img
                      className='badge-img'
                      id={`badge${badge.id}`}
                      src={badge.img}
                      alt={badge.title}
                    />
                  </div>
                );
              });
              badgesDOM = (
                <div className='badges-wrapper' id='powered-by-intel'>
                  {/* <p className='heading'>Powered by Intel&reg;</p> */}
                  <div className='badges'>{badgeItemsDOM}</div>
                </div>
              );
            }
            return (
              <div className={`feature ${orientation}`}>
                <div className='feature-container'>
                  <div className='feature-details'>
                    <p className='feature-heading'>{feature.heading}</p>
                    {feature.subHeadings.map((subHeading) => {
                      return <p className='sub-heading'>{subHeading}</p>;
                    })}
                    {buttonsDOM}
                  </div>
                  <div className='feature-img-container'>
                    {feature.imgs.length > 0 && (
                      <img
                        className='feature-img'
                        src={feature.imgs[state.slideIdx[feature.id]].src}
                        alt='logo'
                      />
                    )}
                  </div>
                </div>
                {cardsDOM}
                {badgesDOM}
              </div>
            );
          })}
        </div>
      </div>

      <div className='news-wrapper'>
        <h2 className='demo-heading'>{features.Testimonial.heading}</h2>
        <Carousel itemsToShow={1}>
          {features.Testimonial.testimonialsItems.map((item, idx) => {
            return (
              <>
                  <VideoSection
                    heading={item.heading}
                    description={item.description}
                    url={item.vid}
                  />
              </>
            );
          })}
        </Carousel>
      </div>

      <div className='logo-wrapper'>
        {features.uySection.imgs.map((item) => {
          return <img src={item.img} alt={item.title} className='uyLogo' />;
        })}
      </div>
      <div className='news-wrapper'>
        <h2 className='demo-heading'>{features.news.heading}</h2>
        <Carousel itemsToShow={1}>
          {features.news.newsItems.map((item) => {
            return (
              <div className='news-item-img-container'>
                {item.link ? (
                  <a href={item.link} className='news-item-img'>
                    <img src={item.img} />
                  </a>
                ) : (
                  <img src={item.img} className='news-item-img' />
                )}
              </div>
            );
          })}
        </Carousel>
      </div>
      <div className='footer-wrapper'>
        <div className='footer footer-details'>
          <div className='about-us'>
            <p className='heading'>About Us</p>
            <p className='description'>{features.footer.aboutUs.description}</p>
          </div>
          <div className='contact-us'>
            <p className='heading'>Contact Us</p>
            <div className='addresses'>
              {features.footer.contactUs.addresses.map((address) => {
                return (
                  <div className='address'>
                    <p className='region'>{address.region}</p>
                    <p>{address.address}</p>
                    <p>Phone: {address.phone}</p>
                  </div>
                );
              })}
            </div>
            <p>Email: {features.footer.contactUs.email}</p>
          </div>
        </div>
        <div className='footer footer-copy'>
          <p>
            &copy; {new Date().getFullYear()} Udyogyantra - All rights reserved
          </p>
          <div className='policy-urls'>
            <a href={policyUrls.TOS} target='_blank'>
              Terms of Service
            </a>
            <a href={policyUrls.privacyPolicy} target='_blank'>
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Landing;
