import ReactPlayer from 'react-player';
import './VideoSection.css'

const VideoSection = (props) => {
    const {heading, description, url} = props;
    return (
        <div className='demo-section'>
					<div className='demo-heading'>
						<h2> {heading} </h2>
					</div>
					<div className='demo-content'>
						<div className='demo-description'>
							<p>{description}</p>
						</div>
						<div className='demo-video'>
							<ReactPlayer
								url={url}
								controls
								playbackRate={1}
								className='demo-player'
							/>
						</div>
					</div>
				</div>
    )
}

const VideoSection1 = (props) => {
    const {heading, description, url} = props;
    return (
        <div className='demo-section'>
					<div className='demo-heading'>
						<h2> {heading} </h2>
					</div>
					<div className='demo-content'>
					<div className='demo-video'>
							<ReactPlayer
								url={url}
								controls
								playbackRate={1}
								className='demo-player'
							/>
						</div>
						<div className='demo-description-left'>
							<p>{description}</p>
						</div>
					</div>
				</div>
    )
}

export {VideoSection,VideoSection1};
