import NutreGrowLogo from "../../images/Landing/NutreGrow-removebg.png";
import NutreGrowBy from "../../images/Landing/poweredby.png";
import hero1 from "../../images/Landing/hero1.png";
import hero2 from "../../images/Landing/hero2.png";
import hero3 from "../../images/Landing/hero3.png";
import hero4 from "../../images/Landing/hero3.png";
import hero5 from "../../images/Landing/hero5.png";
import hero6 from "../../images/Landing/hero6.png";

import children from '../../images/Landing/children.png';
import schoolScan from '../../images/Landing/schoolScan.png';

// import hero7 from '../../images/Landing/hero7.png';
// import hero8 from '../../images/Landing/hero8.png';
// import hero9 from '../../images/Landing/hero9.png';

import profits from "../../images/Landing/profits.svg";
import customers from "../../images/Landing/customers.svg";

import intelGold1 from "../../images/Landing/intel-gold-1.png";
import intelGold2 from "../../images/Landing/intel-gold-2.png";
import nasscom from "../../images/Landing/nasscom.png";
import patent from "../../images/Landing/patent.png";

import feature1_1 from "../../images/Landing/feature1_1.png";
import feature1_2 from "../../images/Landing/feature1_2.png";
import feature1_3 from "../../images/Landing/feature1_3.png";
import feature1_4 from "../../images/Landing/feature1_4.png";
import feature1_5 from "../../images/Landing/feature1_5.png";
import feature1_6 from "../../images/Landing/feature1_6.png";
import feature1_7 from "../../images/Landing/feature1_7.png";
import feature1_8 from "../../images/Landing/feature1_8.png";

import demoStore from "../../images/Landing/store.png";

import feature2_1 from "../../images/Landing/feature2_1.png";
import feature2_2 from "../../images/Landing/feature2_2.png";
import feature2_3 from "../../images/Landing/feature2_3.png";
import feature2_4 from "../../images/Landing/feature2_4.png";
import feature2_5 from "../../images/Landing/feature2_5.png";
import feature2_6 from "../../images/Landing/feature2_6.png";
import feature2_7 from "../../images/Landing/feature2_7.png";

import feature3_1 from "../../images/Landing/feature3_1.png";
import feature3_2 from "../../images/Landing/feature3_2.png";

import feature4_1 from "../../images/Landing/feature4_1.png";
import feature4_2 from "../../images/Landing/feature4_2.png";
import nutreGRODevice from "../../images/nutreGRODevice .png";
import director from "../../images/Landing/Director.png";
import upgovt from "../../images/Landing/1.png";
import NutritionClasses from "../../images/Landing/NutritionClasses.png";
// import feature4_3 from '../../images/Landing/feature4_3.png';
// import feature4_4 from '../../images/Landing/feature4_4.png';
// import feature4_5 from '../../images/Landing/feature4_5.png';
// import feature4_6 from '../../images/Landing/feature4_6.png';

import feature5_1 from "../../images/Landing/feature5_1.png";
import feature5_2 from "../../images/Landing/feature5_2.png";

import feature6_1 from "../../images/Landing/feature6_1.png";
import feature6_2 from "../../images/Landing/feature5_2.png";

import feature7_1 from "../../images/Landing/feature7_1.png";

import feature8_1 from "../../images/Landing/feature8_1.png";
import feature8_2 from "../../images/Landing/feature8_2.png";
import feature8_3 from "../../images/Landing/feature8_3.png";

import feature9_1 from "../../images/Landing/feature9_1.png";

import feature10_1 from "../../images/Landing/feature10_1.png";
import feature10_2 from "../../images/Landing/feature10_2.png";
import feature10_3 from "../../images/Landing/feature10_3.png";

import feature11_1 from "../../images/Landing/feature11_1.png";

import uyLogo from "../../images/Landing/UY_logo.png";

import news1 from "../../images/Landing/news1.png";
import news2 from "../../images/Landing/news2.png";
import news3 from "../../images/Landing/news3.png";
import news4 from "../../images/Landing/news4.png";
import news5 from "../../images/Landing/news5.png";
import news6 from "../../images/Landing/news6.png";
import news7 from "../../images/Landing/news7.png";
import news8 from "../../images/Landing/news8.png";
import news10 from "../../images/Landing/news10.png";
import news11 from "../../images/Landing/news11.png";
import news12 from "../../images/Landing/news12.png";

export const features = {
  header: {
    logo: NutreGrowLogo,
    logoby: NutreGrowBy,
  },
  heroSection: {
    heading: "Enabling Food Security and Equality",
    subHeadings: [
      `100% Traceability Of Every Meal To Individual Students`,
      `Tracks Nutrition, Meal Quality-Quantity & It's Impact on Child Growth at Scale`,
      `Helps Detect Malnutrition Scenarios`,
    ],
    CTA: "Request a Demo",
    login: "Already Registered?",
  },
  featureHeading: "",
  features: [
    {
      id: 0,
      heading: "The Online Restaurant Platform",
      subHeadings: [
        "Enabling Digital Transformation of Restaurants.",
        "Create your own restaurant in a day !",
      ],
      imgs: [
        {
          src: nutreGRODevice,
        },
        {
          src: director,
        },
        {
          src: NutritionClasses,
        },
        {
          src: upgovt,
        },
        {
          src: news10,
        },
        {
          src: children,
        },
        {
          src: schoolScan,
        },
      ],
    },
    {
      id: 1,
      heading: "NutreGro AI Enabled Dashboard",
      subHeadings: ["What Gets Measured Gets Managed.", "- Peter Drucker"],
      cards: [
        {
          text: "Enforces Right Quality & Quantity",
          subText: "by",
          img: profits,
          items: [
            {
              text: "AI – IoT Enabled",
            },
            {
              text: "Real Time Monitoring of Mid Day Meals",
            },
          ],
        },
        {
          text: "No Dependency on Staff to Enter Data",
          subText: "with",
          img: customers,
          items: [
            {
              text: "Loyal Tracing",
            },
            {
              text: "With The Help of Artificial Intelligence",
            },
          ],
        },
      ],
      imgs: [
        {
          src: feature1_1,
        },
        {
          src: feature1_2,
        },
        {
          src: feature1_3,
        },
        {
          src: feature1_4,
        },
        {
          src: feature1_5,
        },
        {
          src: feature1_6,
        },
        {
          src: feature1_7,
        },
        {
          src: feature1_8,
        },
      ],
      orientation: "portrait",
      buttons: {
        demoStore: {
          text: 'Explore a Sample "Internet Restaurant" on RESOEE',
          icon: demoStore,
          href: "https://demo7.resoee.com/store",
        },
      },
    },
    {
      id: 2,
      heading: "NutreGro Trace 100% Meal For Every Individual Student.",
      subHeadings: [
        "30-70 % MDM Budgets Savings – Prevents Overreporting by Schools",
        "Enforces Right Quality & Quantity of Food across all Schools",
      ],
      imgs: [
        {
          src: feature2_1,
        },
        {
          src: feature2_2,
        },
        {
          src: feature2_3,
        },
        {
          src: feature2_4,
        },
        {
          src: feature2_5,
        },
        {
          src: feature2_6,
        },
        {
          src: feature2_7,
        },
      ],
      orientation: "landscape",
    },
    {
      id: 3,
      heading: "Height, Weight and BMI via IoT",
      subHeadings: [
        "Full School Coverage ",
        "Do Remote Child Growth Checks",
        "Real Time Data",
      ],
      imgs: [
        {
          src: feature3_1,
        },
        {
          src: feature3_2,
        },
      ],
      orientation: "landscape",
    },
    {
      id: 4,
      heading: "NutreGro LIVE Inspection & Audit",
      subHeadings: [
        "100% Coverage",
        "We Can See Quantity of Food Taken by Each Child",
        "Meals Temperature at the time of serving (recommendation to serve meals at 60 deg and above to ensure bacteria & viruses are killed)",
      ],
      badges: [
        {
          id: 1,
          title: "Intel Gold Partner",
          img: intelGold1,
          subText: "",
        },
        {
          id: 2,
          title: "Intel IoT Gold Partner",
          img: intelGold2,
          subText: "",
        },
        {
          id: 3,
          title: "Patented Technology",
          img: patent,
          subText: "",
        },
        {
          id: 4,
          title: "NASSCOM",
          img: nasscom,
          subText: "",
        },
      ],
      imgs: [
        {
          src: feature4_1,
        },
        {
          src: feature4_2,
        },
      ],
      orientation: "landscape",
    },
    {
      id: 5,
      heading:
        "Menu Compliance ( are all students getting all items as per menu )",
      subHeadings: [
        // 'Next Generation AI Based',
        // 'Prepared Food Quality & Quantity Authentication & Dispatch Control'
      ],
      imgs: [
        {
          src: feature5_1,
        },
        {
          src: feature5_2,
        },
      ],
      orientation: "landscape",
    },
    {
      id: 6,
      heading:
        "Nutrition Tracking (Proteins, Calories, Carbs each student is getting everyday)",
      subHeadings: [],
      imgs: [
        {
          src: feature6_1,
        },
        {
          src: feature6_2,
        },
      ],
      orientation: "landscape",
    },
    {
      id: 7,
      heading: "BMI tracking ( Underweight, Normal, Overweight & Obese)",
      subHeadings: [],
      imgs: [
        {
          src: feature7_1,
        },
      ],
      orientation: "landscape",
    },
    {
      id: 8,
      heading: "Nutrition and Health Report Card for All Students",
      subHeadings: [],
      imgs: [
        {
          src: feature8_1,
        },
        {
          src: feature8_2,
        },
        {
          src: feature8_3,
        },
      ],
      orientation: "landscape",
    },
    {
      id: 9,
      heading:
        "Nutrition vs BMI correlation  (to do targeted Nutrition Delivery )",
      subHeadings: [],
      imgs: [
        {
          src: feature9_1,
        },
      ],
      orientation: "landscape",
    },
    {
      id: 10,
      heading: "Monitoring Quality of Meals ",
      subHeadings: [],
      imgs: [
        {
          src: feature10_1,
        },
        {
          src: feature10_2,
        },
        {
          src: feature10_3,
        },
      ],
      orientation: "landscape",
    },
    {
      id: 11,
      heading:
        "Check for Pilferage ( Actual Number of Meals being served vs registered students)",
      subHeadings: [],
      imgs: [
        {
          src: feature11_1,
        },
      ],
      orientation: "landscape",
    },
  ],
  Testimonial: {
    heading: "THIS IS WHY WE DO WHAT WE DO. ",
    testimonialsItems: [
      {
        description:
          "I took mid day meal daily but the meal was half cooked. I fell ill and had vomitted a few times. I stopped taking meals thereafter. The quality has improved after the implementation of the device and the food tastes better now. I have again started taking meals daily - Student, ZPHS Patamata",
        vid: "https://youtube.com/shorts/vDWSEtHXqoc?feature=share",
        position: "right",
      },
      {
        description:
          "I am a student of class 9th and studying here (ZPHS, Patamata) form 6th class. I have been taking mid day meals daily but earlier the taste was not good and the eggs were not boiled properly. Chikki wasn’t that great and rice felt half cooked. After the implementation of nutreGro device the quality of food has improved considerably. The quality of eggs and chikkis is also better now. - Student, ZPHS Patamata",
        vid: "https://www.youtube.com/shorts/mOXmgqfRmMc",
        position: "left",
      },
      {
        description:
          "I am a student of 10th class. I joined this school in 8th class. I did not take the mid day meal earlier because I did not like it. Now as the meals are being scanned daily through nutregro device, the food quality has improved and it tastes good. - Student, ZPHS Patamata",
        vid: "https://www.youtube.com/shorts/bihzdhtrrzc",
        position: "right",
      },
      {
        description:
          "Currently I am studying in 9th class and have been in this school from the last 3 years. I have been taking mid day meals daily, but I didn’t like the taste of the food. Rice and eggs were half cooked, chikki was low quality and sambar wasn’t that good either . After the implementation of the machine the food taste has improved. I like to have the mid-day meal now. The food quality has also improved. - Student, ZPHS Patamata",
        vid: "https://www.youtube.com/shorts/RoRQLEb9zgM",
        position: "left",
      },
      {
        description:
          "I am student of 10th class and I joined the school in 7th class.Because I did not like the food taste here, I did not take the meals daily. The quality of food was also low. After the installation of the nutre gro device in my school, the food quality and taste has improved considerably. Now I am taking mid day meal daily. The device informs us of the nutrients present in the meal. We are also able to track our health using the device as we can keep a check on our BMI and growth. Thank you nutregro. - Student, ZPHS Patamata",
        vid: "https://youtube.com/shorts/oUl3agU0WUA?feature=share",
        position: "right",
      },
      {
        description:
          "I have been taking mid day meals daily. However, the food is usually not good and half cooked. After the introduction of nutregro, the food quality and the taste has improved. The meal scans is helping us to know the nutrients present in the meal and through the BMI scanning, we are able to check our health status. - Student, ZPHS Patamata",
        vid: "https://youtube.com/shorts/DDcR7hhuMKo",
        position: "left",
      },
      {
        description:
          "Before the implementation of nutregro device, food was not good. However, after  that, I am taking the mid day meal daily. Now the quality of food and taste has improved. Now I like to eat the mid day meal daily. - Student, ZPHS Patamata",
        vid: "https://www.youtube.com/shorts/yMrlR-BJens",
        position: "right",
      },
      {
        description:
          "By using the nutregro device we are able to track our food intake. The device also tells us about the calories, proteins that we are consuming. BMI scan helps us to know whether we are healthy. It also helps us with how much food we need to have to become healthy. - Student, ZPHS Nidamanuru",
        vid: "https://youtu.be/uP3uK0XDAZ0",
        position: "left",
      },
      {
        description:
          "As a part of Jagananna gorumuddha mid day meal, Nutregro device has been implemented in our school. This device helps to track child nutrition and growth. Through daily meal scans it gives food temperature, proteins, calories present in the food and also identify the egg, chikki and the child who is taking that food. Through BMI scans it gives the child height, weight and BMI which helps to know the health status of every child. This device is giving justice to the children as after the implentation of the device, the quality of food has improved. Thanks to the government for implenting this project in our school.\n -  P.V Ranga Rao, Telugu teacher, K.B.C ZPHS,Patmata",
        vid: "https://www.youtube.com/watch?v=D2nfXiqinFE",
        position: "right",
      },
      {
        description:
          "After the deployment of the nutregro device we are able to track the quantity of food the children are consuming daily. It also indicates how many calories and proteins are present in that food. BMI scans help us to know wheather our children are healthy or underweight. The government has implemented a very useful device for our children.\n-  Bhagya Lakshmi , Parents Committee Vice Chairman, ZPHS NIDMANURU",
        vid: "https://www.youtube.com/watch?v=wU0KX4JaICw",
        position: "left",
      },
    ],
  },
  news: {
    heading: "In the News",
    newsItems: [
      {
        title: "",
        img: news5,
      },
      {
        title: "",
        img: news6,
      },
      {
        title: "",
        img: news7,
      },
      {
        title: "",
        img: news1,
      },
      {
        title: "",
        img: news2,
      },
      {
        title: "",
        img: news3,
      },
      {
        title: "",
        img: news8,
        link: "https://www.coe-iot.com/blog/employing-ai-to-improve-quality-and-traceability-of-mid-day-meal-scheme-in-andhra-pradesh/",
      },
      {
        title: "",
        img: news10,
        link: "https://www.coe-iot.com/blog/employing-ai-to-improve-quality-and-traceability-of-mid-day-meal-scheme-in-andhra-pradesh/",
      },
      {
        title: "",
        img: news11,
      },
      {
        title: "",
        img: news12,
      },
    ],
  },

  uySection: {
    heading: "",
    imgs: [
      {
        title: "This Is UY Logo",
        img: uyLogo,
      },
    ],
  },

  footer: {
    aboutUs: {
      description: `UdyogYantra Technologies are inventors and builders of an AI & IoT powered Internet Restaurant Platform that ensures digitization with authentication in the future of food.
            We enable food businesses to Setup Online Internet Restaurants and go LIVE in 1 Business Day. We Also Provide Complete Kitchen Management System for the Cloud Kitchens, Tightly Integrated Enterprise Solution, Recipe Execution Standardization, Standardization of Food Preparation Process ,Authentication of Quality and Quantity and many more. We enable Dashboard and Data Analytics for Food Enterprise to view the adherence to Quality, Defined Food Preparation Processes, Ingredients across distributed network of kitchens across multiple business models.`,
    },
    contactUs: {
      addresses: [
        {
          region: "India",
          address:
            "505, Jyoti Shikhar Building, District Center Janakpuri, New Delhi 110058",
          phone: "+91-995-886-3566",
        },
        {
          region: "North America",
          address: "1102, 260 Doris Avenue, M2N6X9, Toronto, Canada",
          phone: "+1-416-458-6654",
        },
      ],
      email: "info@udyogyantra.com",
    },
  },
};
