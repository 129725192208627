import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import IconButton from '@material-ui/core/IconButton';
import ReplayIcon from '@material-ui/icons/Replay';
import { api } from '../helpers/urls';
import md5 from 'md5';
import { InputAdornment, Input, OutlinedInput, FormControl, InputLabel } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useSharedState } from '../helpers/sharedState';
import ShowSnackbar from '../components/ShowSnackbar';
import NutreGrow from '../images/Landing/NutreGrow-removebg.png';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#ffffff',
    padding: theme.spacing(4),
    borderRadius: theme.spacing(1)
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    maxWidth: 180,
    marginBottom: theme.spacing(1)
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  'reset-button': {
    color:'blue',
    '&:hover':{
      cursor:'pointer',
    }
  },
  'icon-btn':{
    color:'black'
  }
}));

export default function Register(props) {
  const classes = useStyles();
  const history = useHistory();

  const [businessName, setBusinessName] = React.useState("");
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");

  const [password, setPassword] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [tos, setTos] = React.useState(false);
  const [formSubmission, setFormSubmission] = React.useState(false);

  const [emailError, setEmailError] = React.useState(false);
  const [showEmailVerification, setShowEmailVerification] = React.useState(false);
  const [emailVerified, setEmailVerified] = React.useState(false);
  const [emailToken, setEmailToken] = React.useState("");
  const [emailOtp, setEmailOtp] = React.useState("");
  const [verifyingEmail, setVerifyingEmail] = React.useState(false);
  const [emailOtpError, setEmailOtpError] = React.useState("");
  const [emailDisable, setEmailDisable] = React.useState(false);

  const [phoneError, setPhoneError] = React.useState(false);
  const [showPhoneVerification, setShowPhoneVerification] = React.useState(false);
  const [phoneVerified, setPhoneVerified] = React.useState(false);
  const [phoneToken, setPhoneToken] = React.useState("");
  const [phoneOtp, setPhoneOtp] = React.useState("");
  const [verifyingPhone, setVerifyingPhone] = React.useState(false);
  const [phoneOtpError, setPhoneOtpError] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState('');
  const [phoneDisable, setPhoneDisable] = React.useState(false);

  let [timeLeftPhone, setTimeLeftPhone] = React.useState(60);
  let [timeLeftEmail, setTimeLeftEmail] = React.useState(60);
  const [otpCountdown, setEmailOtpCountDown] = React.useState(null);
  const[ phoneOtpCountdown, setPhoneOtpCountdown] = React.useState(null);
  // const [policyUrls, setPolicyUrls] = React.useState({ 'TOS': '', 'privacyPolicy': '' });

  // async function fetchAppConfig() {
  //   fetch(api.appConfig, {
  //     method: 'GET',
  //     headers: {
  //       'Accept': 'application/json',
  //       'Content-Type': 'application/json'
  //     }
  //   }, 10000)
  //     .then(response => response.json())
  //     .then(
  //       (res) => {
  //         if (res.errorMessage) {
  //           //do nothing for now
  //         } else {
  //           setPolicyUrls(res);
  //         }
  //       },
  //       (err) => {
  //         console.log(err);
  //         //do nothing for now
  //       }
  //     )
  // }

  useEffect(() => {
    // being called in Landing page component
    // fetchAppConfig();
  }, [])

  const { setDialogOpen } = useSharedState();

  function snackbarAlert(message) {
    setErrorMessage(message);
    setDialogOpen(true);
  }

  async function formSubmitHandler() {
    setFormSubmission(true);
    try {
      const response = await fetch(api.register, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "Mobile": phoneNumber,
          "Name": name,
          "Email": email,
          "BuisnessName": businessName,
          "MobileToken": phoneToken,
          "EmailToken": emailToken,
          "Password": password
        })
      }, 5000);
      const res = await response.json();
      if (res.errorMessage) {
        snackbarAlert(res.errorMessage);
      } else {
        //snackbarAlert(res.message);
        //history.push("/login");
        props.setActiveForm('Login');
      }
      setFormSubmission(false);
    } catch (err) {
      snackbarAlert("Could not register account");
      console.log(err);
      setFormSubmission(false);
    }
  };
const emailCounter = ()=>{
  setEmailOtpCountDown(setInterval(() => {
    if (timeLeftEmail <= 0) {
      clearInterval(otpCountdown);
      setTimeLeftEmail(60);
      return;
    }
    setTimeLeftEmail(timeLeftEmail - 1);
    timeLeftEmail = timeLeftEmail - 1;
  }, 1000));
}
const phoneCounter = ()=>{
  setPhoneOtpCountdown(setInterval(() => {
    if (timeLeftPhone <= 0) {
      clearInterval(phoneOtpCountdown);
      setTimeLeftPhone(60);
      return;
    }
    setTimeLeftPhone(timeLeftPhone - 1);
    timeLeftPhone = timeLeftPhone - 1;
  }, 1000));
}
  function checkEmail() {
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
      return true;
    } else {
      return false;
    }
  };

  async function sendOtp(mode, payload) {

    let h = {};
    let reqParams = '';
    if (mode === "Email") {
      emailCounter();
      setEmailDisable(true);
      setTimeout(() => {
        setEmailDisable(false);
      }, 60000);
      //h={"Email":payload};
      reqParams = `?Email=${payload}`
      setEmailOtpError("");
    }
    else if (mode === "Phone") {
      phoneCounter();
      setPhoneDisable(true);
      setTimeout(() => {
        setPhoneDisable(false);
      }, 60000);
      //h={"Phone":payload};
      reqParams = `?Phone=${payload}`
      setPhoneOtpError("");
    }

    try {
      const response = await fetch(api.getOtp + reqParams, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      }, 5000);
      const res = await response.json();
      if (res.errorMessage) {
        if (mode === "Email") {
          setEmailOtpError(res.errorMessage);
          setEmailDisable(false);
        }
        else if (mode === "Phone") {
          setPhoneOtpError(res.errorMessage);
          setPhoneDisable(false);
        }
      } else {
        snackbarAlert(res.message);
        if (mode === "Email") setEmailOtpError("");
        else if (mode === "Phone") setPhoneOtpError("");
      }
    } catch (err) {
      if (mode === "Email") setEmailOtpError("Failed to send OTP, Please retry");
      else if (mode === "Phone") setPhoneOtpError("Failed to send Phone OTP, Please retry");
    }
  }

  async function verifyOtp(mode, otp) {
    let payload;
    if (mode === "Email") { payload = email; setVerifyingEmail(true); }
    else if (mode === "Phone") { payload = phoneNumber; setVerifyingPhone(true); }

    try {
      const response = await fetch(api.verifyOtp + `?Data=${payload}&OTP=${otp}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          //'Data':payload,
          //'OTP':otp
        }
      }, 5000);
      const res = await response.json();
      if (res.errorMessage) {
        snackbarAlert(res.errorMessage);
      } else {
        if (mode === "Email") {
          setEmailVerified(true);
          setEmailToken(res.token);
          setShowEmailVerification(false);
        } else if (mode === "Phone") {
          setPhoneVerified(true);
          setPhoneToken(res.token);
          setShowPhoneVerification(false);
        }
      }
      if (mode === "Email") {
        setVerifyingEmail(false);
      } else if (mode === "Phone") {
        setVerifyingPhone(false);
      }
    } catch (err) {
      snackbarAlert('Could not verify OTP. Please retry');
      if (mode === "Email") {
        setVerifyingEmail(false);
      } else if (mode === "Phone") {
        setVerifyingPhone(false);
      }
    }
  }

  function emailVerificationHandler() {
    if (checkEmail()) {
      setEmailError(false);
      setShowEmailVerification(true);
      sendOtp("Email", email);
    } else {
      setEmailOtpError('Please Enter Correct Email')
      setEmailError(true);
    }
  };


  function checkPhone() {
    if ((/^[0-9]+$/.test(phoneNumber)) && phoneNumber.length === 10) {
      return true;
    } else {
      return false;
    }
  }

  function phoneVerificationHandler() {
    if (checkPhone()) {
      setPhoneError(false);
      setShowPhoneVerification(true);
      sendOtp("Phone", phoneNumber);
    } else {
      setPhoneOtpError('Please Enter Valid Phone Number')
      setPhoneError(true);
    }

  };
  const checkValidKey = (e)=>{
    console.log(e.target.value, typeof e.target.value, e.target.value.length,e, 'check valid key')
    return true;
  }
  return (
    <Container component="main" maxWidth="xs" className={classes.root}>
      <CssBaseline />
      <div className={classes.paper}>
        <img src={NutreGrow} className={classes.logo} />
        <Typography component="h1" variant="h5">
          Sign up for a 30-day free trial
        </Typography>
        <div className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="name"
                label="Name"
                name="name"
                autoComplete="name"
                value={name}
                onChange={e => setName(e.target.value)}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="businessName"
                label="Business/Restaurant Name"
                name="email"
                autoComplete="businessName"
                value={businessName}
                onChange={e=>setBusinessName(e.target.value)}
              />
            </Grid> */}
            <Grid item xs={12}>
              <FormControl fullWidth variant='outlined'>
                <InputLabel required htmlFor="password">Password</InputLabel>
                <OutlinedInput
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant='outlined'>
                <InputLabel required htmlFor="confirm-password">Confirm Password</InputLabel>
                <OutlinedInput
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type={showConfirmPassword ? "text" : "password"}
                  id="confirm-password"
                  autoComplete="confirm-password"
                  value={confirmPassword}
                  onChange={e => setConfirmPassword(e.target.value)}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <Grid style={{ textAlign: 'center' }} item xs={12}>
                <Typography color="error">{password === confirmPassword ? "" : "Please enter the same password"}</Typography>
              </Grid>
            </Grid>
            <Grid item xs={9}>
              <TextField
                disabled={emailVerified || ( !emailOtpError&& showEmailVerification)}
                error={emailError}
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={email}
                type='email'
                onChange={e => setEmail(e.target.value)}
              />
            </Grid>
            <Grid alignItems="stretch" style={{ display: "flex" }} item xs={3}>
              <Button disabled={(showEmailVerification&& !emailOtpError) || emailVerified} onClick={emailVerificationHandler} variant="contained" color="default">
                {emailVerified ? "Verified" : "Verify"}
              </Button>
            </Grid>
            {(showEmailVerification&& !emailOtpError) && <>
              <Grid item xs={6}>
                <TextField
                  required
                  id="emailOtp"
                  label="Enter OTP"
                  value={emailOtp}
                  onChange={e => setEmailOtp(e.target.value)}
                  disabled={verifyingEmail}
                />
              </Grid>
              <Grid alignItems="stretch" style={{ display: "flex" }} item xs={3}>
                <Button disabled={verifyingEmail || emailOtp.length===0} variant="contained" onClick={() => verifyOtp("Email", emailOtp)} color="primary">
                  Submit
              </Button>
              </Grid>
              <Grid alignItems="stretch" style={{ display: "flex", 'justify-content': "center", 'align-items':"center" }} item xs={3}>
                {emailDisable ?  `Resend in ${timeLeftEmail}` :<IconButton disabled={verifyingEmail || emailDisable} onClick={()=>sendOtp("Email",email)}>
                <ReplayIcon className={classes['icon-btn']}/>
              </IconButton>}
                {/* <Button disabled={verifyingEmail || emailDisable} onClick={() => sendOtp("Email", email)}>Resend</Button> */}
              </Grid>
            </>
            }
            { (!emailOtpError &&showEmailVerification )&& <Grid alignItems="stretch" 
            style={{ display: "flex", 'justify-content': "center", 'align-items':"center" }}
            item xs={12} 
            color='primary'
            >
              <div onClick={
              ()=>{setShowEmailVerification(false);
                clearInterval(otpCountdown);
                setTimeLeftEmail(60);}} className={classes['reset-button']} >Edit Email?</div>
            </Grid>}
            {emailOtpError !== "" && <Grid style={{ textAlign: 'center' }} item xs={12}>
              <Typography color="error">{emailOtpError}</Typography>
            </Grid>}
            <Grid item xs={9}>
              <TextField
                disabled={phoneVerified || (showPhoneVerification && !phoneOtpError)}
                error={phoneError}
                variant="outlined"
                required
                fullWidth
                name="phone"
                label="Phone Number"
                type="number"
                id="phone"
                autoComplete="phoneNumber"
                value={phoneNumber}
                onChange={e => setPhoneNumber(e.target.value)}
              />
            </Grid>
            <Grid alignItems="stretch" style={{ display: "flex" }} item xs={3}>
              <Button disabled={(showPhoneVerification && !phoneOtpError) || phoneVerified} onClick={phoneVerificationHandler} variant="contained" color="default">
                {phoneVerified ? "Verified" : "Verify"}
              </Button>
            </Grid>
            {(showPhoneVerification && !phoneOtpError)&& <>
              <Grid item xs={6}>
                <TextField
                  disabled={verifyingPhone}
                  required
                  id="phoneOtp"
                  label="Enter OTP"
                  value={phoneOtp}
                  onChange={e => setPhoneOtp(e.target.value)}
                />
              </Grid>
              <Grid alignItems="stretch" style={{ display: "flex" }} item xs={3}>
                <Button disabled={verifyingPhone || phoneOtp.length===0} variant="contained" onClick={() => verifyOtp("Phone", phoneOtp)} color="primary">
                  Submit
              </Button>
              </Grid>
              <Grid alignItems="stretch" style={{ display: "flex" , 'justify-content': "center", 'align-items':"center"}} item xs={3}>
                {phoneDisable? `Resend in ${timeLeftPhone}`:<IconButton disabled={verifyingPhone || phoneDisable} onClick={()=>sendOtp("Phone",phoneNumber)}>
                <ReplayIcon className={classes['icon-btn']}/>
              </IconButton>}
                {/* <Button disabled={verifyingPhone || phoneDisable} onClick={() => sendOtp("Phone", phoneNumber)}>Resend</Button> */}
              </Grid>
            </>
            }
            { (!phoneOtp &&showPhoneVerification )&& <Grid alignItems="stretch" 
            style={{ display: "flex", 'justify-content': "center", 'align-items':"center" }}
            item xs={12} 
            color='primary'
            >
              <div onClick={
              ()=>{setShowPhoneVerification(false);
                clearInterval(phoneOtpCountdown);
                setTimeLeftPhone(60);}} className={classes['reset-button']} >Edit Phone Number?</div>
            </Grid>}
            {phoneOtpError !== "" && <Grid style={{ textAlign: 'center' }} item xs={12}>
              <Typography color="error">{phoneOtpError}</Typography>
            </Grid>}
            <Grid item xs={2}>
              <Checkbox value="allowExtraEmails" color="primary" value={tos} onChange={e => setTos(!tos)} />
            </Grid>
            <Grid alignItems="stretch" style={{ display: "flex" }} item xs={10}>
              <Typography>I agree to the <a href={props.policyUrls.TOS} target='_blank'>Terms of Service</a> and <a href={props.policyUrls.privacyPolicy} target='_blank'>Privacy Policy</a></Typography>
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={formSubmitHandler}
            disabled={tos === false || password.length === 0 || name.length === 0 || formSubmission || !emailVerified || !phoneVerified}
          >
            {formSubmission ? "Please wait" : "Sign Up"}
          </Button>
          <Grid container justify="center">
            <Grid item>
              <Link variant="body2" href='#signin' onClick={() => props.setActiveForm('Login')}>
                Already have an account? Sign in
                </Link>
            </Grid>
          </Grid>
        </div>
      </div>
      <ShowSnackbar message={errorMessage} />
    </Container>
  );
}
