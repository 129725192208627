import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import { InputAdornment, Input, OutlinedInput, FormControl, InputLabel } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import { Link as RouterLink, useHistory, Redirect } from 'react-router-dom';
import { useSharedState } from '../helpers/sharedState';
import { api } from '../helpers/urls';
import md5 from 'md5';
import ShowSnackbar from '../components/ShowSnackbar';
import NutreGrowLogo from '../images/Landing/NutreGrow-removebg.png';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#ffffff',
    padding: theme.spacing(4),
    borderRadius: theme.spacing(1)
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    maxWidth: 180,
    marginBottom: theme.spacing(1)
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Login(props) {
  const classes = useStyles();
  const history = useHistory();

  const { isLoggedIn, signIn, setIsCompleted, isCompleted, setDialogOpen, signOut, isApproved, setIsApproved, setConsoleUrl } = useSharedState();

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  useEffect(() => {
    signOut();
  }, [])

  function snackbarAlert(message) {
    setErrorMessage(message);
    setDialogOpen(true);
  }

  function handleKeyPress(e) {
    if (email.length === 0 || password.length === 0 || loading) {
      return;
    }
    if (e.key === 'Enter') {
      formSubmitHandler();
    }
  }

  async function formSubmitHandler() {
    setLoading(true);
    try {
      const response = await fetch(api.login, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          'Email': email,
          'Password': password
        })
      }, 5000);
      const res = await response.json();
      if (res.errorMessage) {
        snackbarAlert(res.errorMessage);
      } else {
        if (res.consoleUrl !== '') {
          setConsoleUrl(res.consoleUrl);
        }
        if (res.isApproved) {
          setIsApproved(true);
        }
        if (res.isCompleted) {
          setIsCompleted(true);
        }
        await signIn(res.token, email, res.isCompleted, res.isApproved, res.consoleUrl);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      snackbarAlert("Error while logging in");
      setLoading(false);
    }
  };

  if (isApproved) {
    return <Redirect to='/redirect' />
  }

  if (isCompleted) {
    return <Redirect to='/review' />
  }

  if (isLoggedIn) {
    return <Redirect to="/account" />;
  }

  return (
    <Container component="main" maxWidth="xs" className={classes.root}>
      <CssBaseline />
      <div className={classes.paper}>
        <div className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            onKeyPress={handleKeyPress}
          />
          <FormControl fullWidth variant='outlined'>
            <InputLabel required htmlFor="password">Password</InputLabel>
            <OutlinedInput
              required
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              onKeyPress={handleKeyPress}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={email.length === 0 || password.length === 0 || loading}
            onClick={formSubmitHandler}
          >
            {loading ? "Signing In" : "Sign In"}
          </Button>
          <Grid justify="center" container>
            <Grid item>
              <Link variant="body2" href='#signup' onClick={() => props.setActiveForm('Register')}>
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </div>
      </div>
      <ShowSnackbar message={errorMessage} />
    </Container>
  );
}
